<template>
  <div id="firstLoadPreloader" :class="{'d-none': !show}" v-if="show">

    <div class="container text-center">
      <div class="d-flex align-items-center align-content-center justify-content-center flex-column gap-3">

        <div class="col-10 col-md-8 col-lg-6 col-xl-4 col-xxl-4">
          <img :src="`/img/logo_${activeCountry.code}.svg`" :alt="$t('main-logo-title-alt')" class="img-fluid fade-in">
        </div>

        <div id="loader-wrapper" class="postion-realtive">
          <div id='loader' ></div>
        </div>

        <p class="mt-5">{{ $t('loading') }}...</p>

        <div class="btn btn-outline-second btn-rounded px-5 py-3" type="button" @click="close">
          {{ $t('reset') }}
        </div>
      </div>
    </div>



  </div>
</template>

<script setup lang="ts">
import {useState} from "#app";
import {computed, ref} from "vue";
import {obtainActiveWebCountry} from "../../../composables/master_page";


const firstPreload = computed(() => useState('first_preload', () => true).value);
const activeCountry = obtainActiveWebCountry();

const show = computed(() => {
  return firstPreload.value !== false;
})

const close = () => {
  useState('first_preload').value = false;
}
</script>
